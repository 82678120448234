export const processarObjetos = (objetos: any[]): any[] => {
  const novoArray = new Array(12).fill(0);

  objetos.forEach((objeto) => {
    let mesAno = objeto.mes_ano.split('/')[0];

    mesAno = parseInt(mesAno, 10);

    const indice = mesAno - 1;

    const ajuste =
      objeto.tipo_operacao === 'Saída'
        ? Math.abs(objeto.qtd_ajuste)
        : objeto.qtd_ajuste;

    novoArray[indice] += ajuste;
  });

  return novoArray;
};
