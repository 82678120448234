export const removeDuplicates = (
  array: any[],
  property1: string,
  property2: string,
  property3: string,
  property4: string,
  property5: string,
): any[] => {
  return array.filter(
    (obj, index, self) =>
      index ===
      self.findIndex(
        (t) =>
          t[property1] === obj[property1] &&
          t[property2] === obj[property2] &&
          t[property3] === obj[property3] &&
          t[property4] === obj[property4] &&
          t[property5] === obj[property5],
      ),
  );
};
